import 'bulmaswatch/darkly/bulmaswatch.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './state';
import CellList from './components/cell-list';

const helpText = "### Write code in editor and view the result in preview window.---1. ### show() function show() function can be used to display jsx, react components in the preview window 2. ### Displaying HTML elements ```javascript document.getElementById('root').innerHTML = '<h2><b>Hello World<b><h2>';``` 3. ### Creating a React component ```javascript const App = () => {  return <h1>Hi there</h1> }; show(App); ```"

const App = () => {
  return (
    <Provider store={store}>
      <div>
        <CellList />
      </div>
    </Provider>
  );
};

ReactDOM.render(<App />, document.querySelector('#root'));
